import { SortByCatalogAlias } from "@/types"

export const DEFAULT_IS_SCROLL = true
export const MANUAL_FILTER_KEYS = {
  store: "store",
}
export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 23

export const INITIAL_SORT: SortByCatalogAlias = SortByCatalogAlias.BY_NAME

export const INITIAL_IS_FAST = false
export const INITIAL_IS_ENABLED = true

export enum TogglePageMethod {
  SWITCH = "switch",
  ADDITIONAL = "additional",
}

export const enum MetaMacros {
  NAME = "#name#",
  LOW_PRICE = "#lowPrice#",
  TOTAL = "#total#",
}
